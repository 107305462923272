import {
  isServiceOfferedAsPricingPlan,
  PaymentDtoMapper,
} from '@wix/bookings-uou-mappers';
import {
  OfferedAsType,
  ServicePayment,
  ServicePaymentDto,
} from '@wix/bookings-uou-types';

import { CalendarContext } from '../context/contextFactory';
import {
  CalendarState,
  TFunction,
} from '../../components/BookingCalendar/controller';

export const PRICE_DESCRIPTION_DELIMITER = ' | ';

export const getPaymentDescription = ({
  state,
  context,
}: {
  state: CalendarState;
  context: CalendarContext;
}) => {
  const paymentDescription = [];
  const {
    settings,
    experiments,
    settingsParams,
    isPricingPlanInstalled: isServiceBookableWithPricingPlan,
    businessInfo,
    t,
  } = context;
  const { availableServices, selectedVariantsOptions } = state;
  const regionalSettingsLocale = businessInfo!.regionalSettingsLocale!;
  const isDynamicPricingUoUEnabled = experiments.enabled(
    'specs.bookings.dynamicPricingUoU',
  );

  const payment = availableServices[0].payment;
  const bookingDetailsPricingPlanText = settings.get(
    settingsParams.bookingDetailsPricingPlanText,
  );

  if (isOfferedAsOneTime(payment)) {
    let priceText = getServicePriceText(payment, regionalSettingsLocale, t);

    if (
      isDynamicPricingUoUEnabled &&
      payment.paymentDetails.isVariedPricing &&
      selectedVariantsOptions.length > 0 &&
      selectedVariantsOptions[0].price
    ) {
      priceText = selectedVariantsOptions[0].price;
    }

    paymentDescription.push(priceText);
  }
  if (
    isServiceOfferedAsPricingPlan(payment, isServiceBookableWithPricingPlan)
  ) {
    paymentDescription.push(bookingDetailsPricingPlanText);
  }
  return (
    paymentDescription
      // remove empty items
      .filter((priceItem) => !!priceItem.trim())
      .join(PRICE_DESCRIPTION_DELIMITER)
  );
};

export const isOfferedAsOneTime = (payment: ServicePayment) => {
  return payment.offeredAs.indexOf(OfferedAsType.ONE_TIME) >= 0;
};

export const getServicePriceText = (
  payment: ServicePayment,
  regionalSettingsLocale: string,
  t?: TFunction,
) => {
  const paymentDto: ServicePaymentDto = payment.paymentDetails;
  const paymentDtoMapper = new PaymentDtoMapper(
    regionalSettingsLocale,
    (price) =>
      paymentDto.isVariedPricing && t
        ? t('payment.from-price', { price })
        : price,
  );
  return paymentDtoMapper.priceText(paymentDto);
};

import { LayoutOptions } from '../types/types';

export const isLayoutWithTimeSlot = (
  settings: any,
  settingsParams: any,
): boolean =>
  isWeeklyTimeSlotsLayout(settings, settingsParams) ||
  isDailyTimeSlotsWeeklyPickerLayout(settings, settingsParams) ||
  isDailyTimeSlotsMonthlyPickerLayout(settings, settingsParams);

export const isDailyTimeSlotsMonthlyPickerLayout = (
  settings: any,
  settingsParams: any,
): boolean =>
  settings.get(settingsParams.calendarLayout) ===
  LayoutOptions.DAILY_TIME_SLOTS_MONTHLY_PICKER;

export const isDailyTimeSlotsWeeklyPickerLayout = (
  settings: any,
  settingsParams: any,
): boolean =>
  settings.get(settingsParams.calendarLayout) ===
  LayoutOptions.DAILY_TIME_SLOTS_WEEKLY_PICKER;

export const isDailyAgendaWeeklyPickerLayout = (
  settings: any,
  settingsParams: any,
): boolean =>
  settings.get(settingsParams.calendarLayout) ===
  LayoutOptions.DAILY_AGENDA_WEEKLY_PICKER;

export const isWeeklyTimeSlotsLayout = (
  settings: any,
  settingsParams: any,
): boolean =>
  settings.get(settingsParams.calendarLayout) ===
  LayoutOptions.WEEKLY_TIME_SLOTS;

export const isWeeklyTimetableLayout = (
  settings: any,
  settingsParams: any,
): boolean =>
  settings.get(settingsParams.calendarLayout) ===
  LayoutOptions.WEEKLY_TIMETABLE;

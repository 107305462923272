import { ViewModelFactoryParams } from '../../../../utils/ControlledComponent/ControlledComponent.types';
import {
  createFilterViewModels,
  FilterViewModel,
  memoizedFiltersViewModel,
} from '../filterViewModel/filterViewModel';
import { CalendarContext } from '../../../../utils/context/contextFactory';
import { CalendarState } from '../../controller';
import { Optional, SourceOptions } from '../../../../types/types';
import { MemoizedViewModalFactory } from '../viewModel';
import {
  createTimezoneSelectionViewModel,
  memoizedTimezoneSelectionViewModel,
  TimezoneSelectionViewModel,
} from '../timezoneSelectionViewModel/timezoneSelectionViewModel';
import { isCalendarPage } from '../../../../utils/presets';

export type HeaderViewModel = {
  title?: string;
  subtitle?: string;
  filters: FilterViewModel[];
  timezoneSelectionViewModel?: TimezoneSelectionViewModel;
};

export const memoizedHeaderViewModel: MemoizedViewModalFactory<
  Optional<HeaderViewModel>
> = {
  dependencies: {
    state: ['availableServices'],
    settings: [
      'headerSubtitleVisibility',
      'headerSubtitleSource',
      'headerSubtitle',
      'headerFiltersVisibility',
      'headerTitle',
      'headerTitleVisibility',
      'headerTimezoneSelectionVisibility',
    ],
    subDependencies: [
      memoizedFiltersViewModel.dependencies,
      memoizedTimezoneSelectionViewModel.dependencies,
    ],
  },
  createViewModel: createHeaderViewModel,
};

export function createHeaderViewModel({
  state,
  context,
}: ViewModelFactoryParams<
  CalendarState,
  CalendarContext
>): Optional<HeaderViewModel> {
  const {
    settings,
    settingsParams,
    getContent,
    experiments,
    isMobile,
    preset,
  } = context;
  const { availableServices } = state;

  const isFiltersSeparationEnabled = experiments.enabled(
    'specs.bookings.calendarFiltersSeparation',
  );
  const isCalendar = isCalendarPage(preset);

  let title: string | undefined;

  if (isCalendar) {
    title = availableServices[0].info.name;
  } else {
    const isTitleVisible = settings.get(settingsParams.headerTitleVisibility);

    title = isTitleVisible
      ? getContent({
          settingsParam: settingsParams.headerTitle,
          translationKey: 'app.settings.defaults.header-title',
        })
      : undefined;
  }

  let subtitle;
  const isSubtitleVisible =
    isCalendar && settings.get(settingsParams.headerSubtitleVisibility);

  if (isSubtitleVisible) {
    if (
      settings.get(settingsParams.headerSubtitleSource) ===
      SourceOptions.SERVICE
    ) {
      subtitle = availableServices[0].info.tagline;
    } else {
      subtitle = getContent({
        settingsParam: settingsParams.headerSubtitle,
        translationKey: 'app.settings.defaults.header-subtitle',
      });
    }
  }

  const isFiltersVisible =
    isFiltersSeparationEnabled ||
    settings.get(settingsParams.headerFiltersVisibility);
  const filters = isFiltersVisible
    ? createFilterViewModels({ state, context })
    : [];

  const isTimezoneSelectionVisible = settings.get(
    settingsParams.headerTimezoneSelectionVisibility,
  );
  const timezoneSelectionViewModel = isTimezoneSelectionVisible
    ? createTimezoneSelectionViewModel({
        state,
        context,
      })
    : undefined;

  const isAtLeastOneHeaderElementExists =
    !!title || !!subtitle || filters.length || !!timezoneSelectionViewModel;
  if (isAtLeastOneHeaderElementExists) {
    return {
      title,
      subtitle,
      filters,
      timezoneSelectionViewModel,
    };
  }
}

import { CalendarState } from '../../controller';
import { MemoizedViewModalFactory } from '../viewModel';
import { ViewModelFactoryParams } from '../../../../utils/ControlledComponent/ControlledComponent.types';
import { CalendarContext } from '../../../../utils/context/contextFactory';
import {
  SlotAvailability,
  SlotResource,
} from '@wix/ambassador-availability-calendar/types';
import { formatRfcTimeStringToTimeSlotView } from '../../../../utils/dateAndTime/dateAndTime';
import {
  getServicePriceText,
  isOfferedAsOneTime,
} from '../../../../utils/payment/payment';
import { getSlotDuration } from '../../../../utils/duration/duration';
import { getLocationText } from '../../../../utils/bookingPreferences/bookingPreferences';
import { Optional } from '../../../../types/types';
import { ServicePayment } from '@wix/bookings-uou-types';
import { getFormattedPrice } from '../../../../utils/dynamicPricing/dynamicPricing';

export type AgendaSlot = {
  id: number;
  formattedTime: string;
  serviceName: string;
  duration?: string;
  location?: string;
  staffMember?: string;
  price?: string;
  spotsLeft?: string;
  policy?: string;
  cta?: string;
};

export type AgendaSlotsViewModel = AgendaSlot[];
export type EnrichedSlotAvailability = SlotAvailability & { id: number };

type AgendaSlotsViewModelParams = ViewModelFactoryParams<
  CalendarState,
  CalendarContext
> & {
  slots: EnrichedSlotAvailability[];
};

export const enrichSlotAvailability = (
  slotsAvailability?: SlotAvailability[],
) => {
  return (
    slotsAvailability?.map((slotAvailability, index) => ({
      ...slotAvailability,
      id: index,
    })) || []
  );
};

export const memoizedAgendaSlotsViewModel: MemoizedViewModalFactory<AgendaSlotsViewModel> =
  {
    dependencies: {
      settings: [
        'spotsLeftFormat',
        'spotsLeftVisibility',
        'slotDurationVisibility',
        'slotLocationVisibility',
        'slotPriceVisibility',
        'slotRegistrationStatusVisibility',
        'slotStaffMemberVisibility',
      ],
      state: ['availableServices'],
    },
  };

export function createAgendaSlotsViewModel({
  state,
  context,
  slots,
}: AgendaSlotsViewModelParams): AgendaSlotsViewModel {
  const { availableServices } = state;
  const { t, getContent, settingsParams, settings } = context;
  const dateRegionalSettingsLocale =
    context.businessInfo!.regionalSettingsLocale!;

  const isLocationVisible = settings.get(settingsParams.slotLocationVisibility);
  const isDurationVisible = settings.get(settingsParams.slotDurationVisibility);
  const isPriceVisible = settings.get(settingsParams.slotPriceVisibility);
  const isSpotsLeftVisible = settings.get(settingsParams.spotsLeftVisibility);
  const isStaffMemberVisible = settings.get(
    settingsParams.slotStaffMemberVisibility,
  );

  return (
    slots?.map<AgendaSlot>((enrichedSlot) => {
      const slot = enrichedSlot.slot!;
      const service = availableServices.find(
        (service) => service.id === slot.serviceId,
      );

      const rfcStartTime = slot.startDate!;
      const rfcEndTime = slot.endDate!;

      const formattedTime = formatRfcTimeStringToTimeSlotView(rfcStartTime);
      const serviceName = service?.info.name!;

      const location = isLocationVisible
        ? getLocationText(slot.location, t)
        : undefined;

      const staffMember = slot.resource!;
      const staffMemberName = isStaffMemberVisible
        ? staffMember.name
        : undefined;

      const duration = isDurationVisible
        ? getSlotDuration({
            dateRegionalSettingsLocale,
            rfcStartTime,
            rfcEndTime,
            t,
          }).durationText
        : undefined;

      const price = isPriceVisible
        ? getSlotPrice({
            payment: service!.payment,
            staffMember,
            context,
            state,
          })
        : undefined;

      let spotsLeft: string | undefined;
      if (isSpotsLeftVisible) {
        const openSpots = enrichedSlot.openSpots;
        const spotsLeftFormat = getContent({
          settingsParam: settingsParams.spotsLeftFormat,
          translationKey: 'app.settings.defaults.slots.spots-left',
        });

        spotsLeft = t('app.agenda-slot.spots-left.label', {
          openSpots,
          spotsLeftFormat,
        });
      }

      return {
        id: enrichedSlot.id,
        formattedTime,
        serviceName,
        location,
        spotsLeft,
        duration,
        price,
        staffMember: staffMemberName,
      };
    }) || []
  );
}

function getSlotPrice({
  payment,
  staffMember,
  context,
  state,
}: {
  payment: ServicePayment;
  staffMember: SlotResource;
  context: CalendarContext;
  state: CalendarState;
}): Optional<string> {
  const regionalSettingsLocale = context.businessInfo!.regionalSettingsLocale!;

  if (isOfferedAsOneTime(payment)) {
    if (payment.paymentDetails.isVariedPricing) {
      return getFormattedPrice({
        state,
        context,
        payment,
        choiceId: staffMember.id!,
        optionId: state.serviceVariants?.options?.[0].id!,
      });
    }

    return getServicePriceText(payment, regionalSettingsLocale);
  }
}

import { Service, ServiceType } from '@wix/bookings-uou-types';
import { GetActiveFeaturesResponse } from '@wix/ambassador-services-catalog-server/types';

export const isCalendarFlow = (service: Service) =>
  service?.info.type !== ServiceType.COURSE &&
  service?.policy.isBookOnlineAllowed;

export function isBookingsEnabled({
  serviceType,
  activeFeatures,
  isTemplateMode,
}: {
  serviceType: ServiceType;
  activeFeatures: GetActiveFeaturesResponse;
  isTemplateMode: boolean;
}): boolean {
  if (isTemplateMode) {
    return true;
  }

  switch (serviceType) {
    case ServiceType.GROUP:
      return !!activeFeatures.applicableForGroups;
    case ServiceType.INDIVIDUAL:
      return !!activeFeatures.applicableForIndividual;
    default:
      return true;
  }
}

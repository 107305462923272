import { ViewModelFactoryParams } from '../../../../utils/ControlledComponent/ControlledComponent.types';
import { CalendarState } from '../../controller';
import { CalendarContext } from '../../../../utils/context/contextFactory';
import { MemoizedViewModalFactory } from '../viewModel';
import { isSlotStartsOnDate } from '../../../../utils/timeSlots/timeSlots';
import {
  createWeekPickerViewModel,
  memoizedWeekPickerViewModel,
} from '../weekPickerViewModel/weekPickerViewModel';
import { WeeklyTimetableLayoutViewModel } from '../bodyViewModel/bodyViewModel.types';
import {
  getSlotsPerDaysInSelectedRange,
  SlotsFactory,
} from '../../../../utils/slotsPerDay/slotsPerDay';
import {
  AgendaSlotsViewModel,
  createAgendaSlotsViewModel,
  enrichSlotAvailability,
  memoizedAgendaSlotsViewModel,
} from '../agendaSlotsViewModel/agendaSlotsViewModel';

export const memoizedWeeklyTimetableLayoutViewModel: MemoizedViewModalFactory<WeeklyTimetableLayoutViewModel> =
  {
    dependencies: {
      state: ['slotsStatus', 'availableSlots', 'selectedRange'],
      settings: ['calendarLayout', 'noSessionsOffered'],
      subDependencies: [
        memoizedWeekPickerViewModel.dependencies,
        memoizedAgendaSlotsViewModel.dependencies,
      ],
    },
    createViewModel: createWeeklyTimetableLayoutViewModel,
  };

export function createWeeklyTimetableLayoutViewModel({
  state,
  context,
}: ViewModelFactoryParams<
  CalendarState,
  CalendarContext
>): WeeklyTimetableLayoutViewModel {
  const { slotsStatus, selectedRange } = state;

  let slotsPerDays;
  let weekPickerViewModel;
  if (selectedRange) {
    const slotsFactory = createAgendaSlotsFactory(state);
    slotsPerDays = getSlotsPerDaysInSelectedRange<AgendaSlotsViewModel>({
      slotsFactory,
      state,
      context,
    });
    weekPickerViewModel = createWeekPickerViewModel({ state, context });
  }

  return {
    slotsStatus,
    weekPickerViewModel,
    slotsPerDays,
  };
}

export const createAgendaSlotsFactory = (
  calendarState: CalendarState,
): SlotsFactory<AgendaSlotsViewModel> => {
  const { availableSlots } = calendarState;
  const enrichedSlotsAvailability = enrichSlotAvailability(
    availableSlots?.availabilityEntries,
  );

  return (
    dateAsLocalDate: string,
    state: CalendarState,
    context: CalendarContext,
  ) => {
    const enrichedSlotsAtSpecificDate =
      enrichedSlotsAvailability?.filter((slotAvailability) =>
        isSlotStartsOnDate({
          rfcStartTime: slotAvailability!.slot!.startDate!,
          dateAsLocalDate,
        }),
      ) || [];

    return createAgendaSlotsViewModel({
      state,
      context,
      slots: enrichedSlotsAtSpecificDate,
    });
  };
};

import { SlotAvailability } from '@wix/ambassador-availability-calendar/types';
import { ActionFactoryParams } from '../../../../utils/ControlledComponent/ControlledComponent.types';
import { CalendarState } from '../../controller';
import { CalendarContext } from '../../../../utils/context/contextFactory';
import { NavigateToNextPage } from '../navigateToNextPage/navigateToNextPage';
import { isSlotWithOpenWaitingList } from '../../../../utils/timeSlots/timeSlots';

export type OnSlotSelected = (selectedSlotsIds: number[]) => void;

export function createOnSlotSelectedAction(
  actionFactoryParams: ActionFactoryParams<CalendarState, CalendarContext>,
  navigateToNextPage: NavigateToNextPage,
): OnSlotSelected {
  return async (selectedSlotsIds) => {
    const { getControllerState } = actionFactoryParams;
    const [state, setState] = getControllerState();
    const { rescheduleBookingDetails, availableSlots } = state;

    const selectedSlotId = selectedSlotsIds[0];
    const selectedSlot: SlotAvailability =
      availableSlots!.availabilityEntries![selectedSlotId];

    const isWaitingList = isSlotWithOpenWaitingList(selectedSlot);

    setState({ selectableSlotsAtSelectedTime: [selectedSlot] });

    await navigateToNextPage({
      selectedSlots: [selectedSlot],
      bookingFlow: {
        isWaitingList,
        isRescheduling: !!rescheduleBookingDetails,
      },
    });
  };
}

import { CalendarState } from '../../controller';
import { CalendarContext } from '../../../../utils/context/contextFactory';
import { ActionFactoryParams } from '../../../../utils/ControlledComponent/ControlledComponent.types';
import {
  getFormattedPrice,
  getVariantByChoiceIdAndOptionId,
} from '../../../../utils/dynamicPricing/dynamicPricing';

export type OnServiceVariantsOptionSelected = (options: {
  choiceId: string;
  optionId: string;
  numberOfParticipants: number;
}) => void;

export function createOnServiceVariantsOptionSelected({
  getControllerState,
  context,
}: ActionFactoryParams<
  CalendarState,
  CalendarContext
>): OnServiceVariantsOptionSelected {
  return ({
    choiceId,
    optionId,
    numberOfParticipants,
  }: {
    choiceId: string;
    optionId: string;
    numberOfParticipants: number;
  }) => {
    const [state, setState] = getControllerState();
    const { availableServices, serviceVariants } = state;

    const payment = availableServices[0].payment!;

    const variant = getVariantByChoiceIdAndOptionId(
      serviceVariants,
      choiceId,
      optionId,
    );
    const formattedPrice = getFormattedPrice({
      state,
      context,
      payment,
      choiceId,
      optionId,
    });

    setState({
      selectedVariantsOptions: [
        {
          price: formattedPrice,
          numberOfParticipants,
          optionIdToVariants: {
            [optionId]: variant
              ? variant.choices!
              : [{ optionId, staffMemberId: choiceId }],
          },
        },
      ],
    });
  };
}

import { PriceUtils } from '@wix/bookings-uou-mappers';
import { DynamicPriceInfo, ServicePayment } from '@wix/bookings-uou-types';
import {
  ServiceChoice,
  ServiceVariant,
  ServiceOptionType,
  ServiceOptionsAndVariants,
} from '@wix/ambassador-bookings-catalog-v1-service-options-and-variants/types';

import { CalendarContext } from '../context/contextFactory';
import { Optional, SelectedVariantOptions } from '../../types/types';
import { CalendarState } from '../../components/BookingCalendar/controller';

export const getDynamicPricingInfo = (
  selectedVariantsOptions: SelectedVariantOptions[],
): Optional<DynamicPriceInfo> => {
  if (selectedVariantsOptions.length > 0) {
    return {
      selectedVariants: selectedVariantsOptions.map(
        ({ optionIdToVariants, numberOfParticipants }) => {
          const optionIds = Object.keys(optionIdToVariants);
          return {
            numberOfParticipants,
            choices: optionIds.map(
              (optionId): ServiceChoice => optionIdToVariants[optionId][0],
            ),
          };
        },
      ),
    };
  }
};

export const getFormattedPrice = ({
  context,
  state,
  payment,
  choiceId,
  optionId,
}: {
  context: CalendarContext;
  state: CalendarState;
  payment: ServicePayment;
  choiceId: string;
  optionId: string;
}): string => {
  const { businessInfo } = context;
  const { serviceVariants } = state;
  const regionalSettingsLocale = businessInfo!.regionalSettingsLocale!;
  const variant = getVariantByChoiceIdAndOptionId(
    serviceVariants,
    choiceId,
    optionId,
  );

  if (variant) {
    return PriceUtils.getFormattedCurrency({
      price: Number(variant.price!.value),
      currency: variant.price!.currency!,
      locale: regionalSettingsLocale,
    });
  } else {
    return PriceUtils.getFormattedCurrency({
      price: payment.paymentDetails.price,
      currency: payment.paymentDetails.currency,
      locale: regionalSettingsLocale,
    });
  }
};

export const getVariantByChoiceIdAndOptionId = (
  serviceVariants: Optional<ServiceOptionsAndVariants>,
  choiceId: string,
  optionId: string,
): Optional<ServiceVariant> => {
  return serviceVariants?.variants?.find(
    ({ choices }) =>
      choices?.[0]?.optionId === optionId &&
      (choices?.[0]?.staffMemberId === choiceId ||
        choices?.[0]?.custom === choiceId),
  );
};

export const isServiceVariantWithStaff = (
  serviceVariants: Optional<ServiceOptionsAndVariants>,
): boolean => {
  return isServiceVariantWithType(
    serviceVariants,
    ServiceOptionType.STAFF_MEMBER,
  );
};

export const isServiceVariantWithCustom = (
  serviceVariants: Optional<ServiceOptionsAndVariants>,
): boolean => {
  return isServiceVariantWithType(serviceVariants, ServiceOptionType.CUSTOM);
};

const isServiceVariantWithType = (
  serviceVariants: Optional<ServiceOptionsAndVariants>,
  serviceOptionType: ServiceOptionType,
): boolean => {
  return !!(
    serviceVariants &&
    serviceVariants.options?.some(({ type }) => type === serviceOptionType)
  );
};
